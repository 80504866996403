/* Stripe related configuration.

NOTE: REACT_APP_STRIPE_PUBLISHABLE_KEY is mandatory environment variable.
This variable is set in a hidden file: .env
To make Stripe connection work, you also need to set Stripe's private key in the Sharetribe Console.
*/

export const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. However, US accounts can hold funds up to 2 years.
// https://docs.stripe.com/connect/manual-payouts
//
// If your marketplace is for US only, you should also be aware that available
// time slots can only be fetched for 366 days into the future.
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots
export const dayCountAvailableForBooking = 90;

/**
 * Default merchant category code (MCC)
 * MCCs are used to classify businesses by the type of goods or services they provide.
 *
 * In this template, we use code 5734 Computer Software Stores as a default for all the connected accounts.
 *
 * See the whole list of MCC codes from https://stripe.com/docs/connect/setting-mcc#list
 */
export const defaultMCC = '5734';

/*
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global

You can find the bank account formats from https://stripe.com/docs/connect/payouts-bank-accounts
*/

export const supportedCountries = [
  {
    //Australia
    code: 'AU',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    // Austria
    code: 'AT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Belgium
    code: 'BE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Bulgraia
    code: 'BG',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Canada
    code: 'CA',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    //Cyprus
    code: 'CY',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //	Czech Republic
    code: 'CZ',
    currency: 'CZK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Denmark
    code: 'DK',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Estionia
    code: 'EE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Finland
    code: 'FI',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // France
    code: 'FR',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Germany
    code: 'DE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Greece
    code: 'GR',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Hong Kong
    code: 'HK',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Ireland
    code: 'IE',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Italy
    code: 'IT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Japan
    code: 'JP',
    currency: 'JPY',
    accountConfig: {
      bankName: true,
      branchName: true,
      bankCode: true,
      branchCode: true,
      accountNumber: true,
      accountOwnerName: true,
    },
  },
  {
    // Latvia
    code: 'LV',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Lithuania
    code: 'LT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Luxembourg
    code: 'LU',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Malta
    code: 'MT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mexico
    code: 'MX',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Netherlands
    code: 'NL',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // New Zealand
    code: 'NZ',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Norway
    code: 'NO',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Poland
    code: 'PL',
    currency: 'PLN',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Portugal
    code: 'PT',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Romania
    code: 'RO',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Singapore
    code: 'SG',
    currency: 'SGD',
    accountConfig: {
      bankCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Slovakia
    code: 'SK',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Slovenia
    code: 'SI',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Spain
    code: 'ES',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sweden
    code: 'SE',
    currency: 'SEK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Switzerland
    code: 'CH',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // United Kingdom
    code: 'GB',
    currency: 'GBP',
    accountConfig: {
      sortCode: true,
      accountNumber: true,
    },
  },
  {
    // United States
    code: 'US',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
];

export const paymentLinks = {
  jetski_single: {
    name: 'Jetski (Single)',
    description: 'Casual owners renting one personal watercraft',
    monthlyPrice: '$50',
    yearlyPrice: '$540',
    monthly: {
      dev: 'https://buy.stripe.com/test_00gdTvdJYa1O1MsdQQ',
      prod: 'https://buy.stripe.com/8wM3cr9zw8XY3DieUU',
    },
    yearly: {
      dev: 'https://buy.stripe.com/test_fZe7v7bBQgqc0IoeUV',
      prod: 'https://buy.stripe.com/14k9AP7roa226Pu6op',
    },
  },
  jetski_multiple: {
    name: 'Jetski (Multiple)',
    description: 'Owners with multiple Jetskis who want a simple flat fee',
    monthlyPrice: '$75',
    yearlyPrice: '$810',
    monthly: {
      dev: 'https://buy.stripe.com/test_aEU02F9tI3DqfDiaEQ',
      prod: 'https://buy.stripe.com/7sI14j6nk4HI7TybIU',
    },
    yearly: {
      dev: 'https://buy.stripe.com/test_8wM5mZ6hwgqcfDi6oB',
      prod: 'https://buy.stripe.com/3cs7sHcLI5LMehWeV7',
    },
  },
  vessels_1_2: {
    name: '1-2 Vessels',
    description:
      '<b>Best For:</b> Individual boat owners or small rental business just starting out',
    monthlyPrice: '$99',
    yearlyPrice: '$1069',
    monthly: {
      dev: 'https://buy.stripe.com/test_28o16JaxMc9Wcr6bIK',
      prod: 'https://buy.stripe.com/6oE5kzh1Ya22ehW3cs',
    },
    yearly: {
      dev: 'https://buy.stripe.com/test_aEU6r3eO21vi9eU3cf',
      prod: 'https://buy.stripe.com/3cs00f9zw8XY8XC4gx',
    },
  },
  vessels_3_5: {
    name: '3-5 Vessels',
    description: '<b>Best For:</b> Small fleet operators looking for more exposure',
    monthlyPrice: '$199',
    yearlyPrice: '$2149',
    monthly: {
      dev: 'https://buy.stripe.com/test_00g7v7fS63Dqbn27sw',
      prod: 'https://buy.stripe.com/fZecN19zwfmm4Hm7sK',
    },
    yearly: {
      dev: 'https://buy.stripe.com/test_8wM16JdJY4Hueze5kp',
      prod: 'https://buy.stripe.com/7sIeV96nkcaafm03cv',
    },
  },
  vessels_6_plus: {
    name: '6+ Vessels',
    description: '<b>Best For:</b> Large-scale rental businesses or marinas with multiple vessels',
    monthlyPrice: '$299',
    yearlyPrice: '$3229',
    monthly: {
      dev: 'https://buy.stripe.com/test_cN26r37lAb5SfDi7sy',
      prod: 'https://buy.stripe.com/8wMcN1eTQ7TU6PueVe',
    },
    yearly: {
      dev: 'https://buy.stripe.com/test_00g4iVeO20re62I6ov',
      prod: 'https://buy.stripe.com/8wM6oDbHE6PQ4HmbJ3',
    },
  },
  charter_brokers: {
    name: 'Charter Brokers',
    description: '<b>Best For:</b> Charter Brokers with boats +55ft',
    monthlyPrice: '$400',
    yearlyPrice: '$4320',
    monthly: {
      dev: 'https://buy.stripe.com/test_00gbLn0Xc3Dq76M3cm',
      prod: 'https://buy.stripe.com/7sIeV9fXUgqq8XC3cm',
    },
    yearly: {
      dev: 'https://buy.stripe.com/test_00g02F0Xc1vi3UAdR1',
      prod: 'https://buy.stripe.com/dR6dR55jga22gq45kv',
    },
  },
};
