import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { updateUserPlan } from '../../ducks/user.duck';

import { NamedRedirect, UserNav } from '../../components';
import { withRouter } from 'react-router-dom';
import { ensureCurrentUser } from '../../util/data';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import Spinner from '../../components/IconSpinner/IconSpinner';
import { isScrollingDisabled } from '../../ducks/ui.duck';

export const UserOnboardingPageComponent = props => {
  const {
    currentUser,
    onUpdateUserPlan,
    updateUserPlanInProgress,
    updateUserPlanError,
    scrollingDisabled,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const userType = user.attributes.profile.metadata?.userType;
  const userPlan = user.attributes.profile.metadata?.userPlan;
  const subscriptionType = user.attributes.profile.metadata?.subscriptionType;

  const urlParams = new URLSearchParams(window.location.search);
  const selectedPlan = urlParams.get('selectedPlan');

  console.log('Data: ', currentUserLoaded, userType, userPlan, subscriptionType, selectedPlan);

  if (currentUserLoaded) {
    if (userType !== 'owner') {
      console.log('Redirecting to LandingPage...');
      return <NamedRedirect name="LandingPage" />;
    } else {
      if (!userPlan) {
      } else if (userPlan === 'transaction') {
        console.log('Redirecting to NewListingPage...');
        return <NamedRedirect name="NewListingPage" />;
      } else {
        if (subscriptionType) {
          console.log('Redirecting to NewListingPage...');
          return <NamedRedirect name="NewListingPage" />;
        } else {
          console.log('Redirecting to SubscriptionSelectionPage...');
          return <NamedRedirect name="SubscriptionSelectionPage" />;
        }
      }
    }
  } /* else not needed */

  useEffect(() => {
    if (selectedPlan && userType === 'owner' && !userPlan) {
      onUpdateUserPlan(selectedPlan);
    }
  }, [selectedPlan, onUpdateUserPlan, userType, userPlan]);

  return (
    // TODO-GB: Move title to translation file
    <Page title="User Onboarding" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer currentPage="UserOnboardingPage" />
            <UserNav currentPage="UserOnboardingPage" />
          </>
        }
        footer={<FooterContainer />}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
          }}
        >
          {updateUserPlanInProgress && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
              {/* TODO-GB: Move message to translation file */}
              <p>Please wait, your plan is being updated!</p>
            </div>
          )}
          {updateUserPlanError && (
            <p>
              {/* TODO-GB: Move error message to translation file */}
              Error updating plan:{' '}
              {updateUserPlanError?.message ||
                'An unexpected error occurred. Please try again later.'}
            </p>
          )}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

UserOnboardingPageComponent.defaultProps = {
  currentUser: null,
  updateUserPlanInProgress: false,
  updateUserPlanError: null,
  scrollingDisabled: false,
};

UserOnboardingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  onUpdateUserPlan: func.isRequired,
  updateUserPlanInProgress: bool,
  updateUserPlanError: propTypes.error,
  scrollingDisabled: bool,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  updateUserPlanInProgress: state.user.updateUserPlanInProgress,
  updateUserPlanError: state.user.updateUserPlanError,
  scrollingDisabled: isScrollingDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateUserPlan: plan => dispatch(updateUserPlan(plan)),
});

const UserOnboardingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserOnboardingPageComponent);

export default UserOnboardingPage;
