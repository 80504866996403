import React, { useState } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';

import { NamedRedirect, UserNav } from '../../components';
import { withRouter } from 'react-router-dom';
import { ensureCurrentUser } from '../../util/data';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import css from './SubscriptionSelectionPage.module.css';
import { paymentLinks } from '../../config/configStripe';

export const SubscriptionSelectionPageComponent = props => {
  const { currentUser, scrollingDisabled } = props;
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState('monthly');

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const userType = user.attributes.profile.metadata?.userType;
  const userPlan = user.attributes.profile.metadata?.userPlan;
  const subscriptionType = user.attributes.profile.metadata?.subscriptionType;

  console.log('Data: ', currentUserLoaded, userType, userPlan, subscriptionType);

  if (currentUserLoaded) {
    if (userType !== 'owner') {
      return <NamedRedirect name="LandingPage" />;
    } else {
      if (!userPlan) {
        // return <NamedRedirect name="CMSPage" params={{ pageId: 'onboarding-page' }} />;
      } else if (userPlan === 'transaction') {
        return <NamedRedirect name="NewListingPage" />;
      } else {
        if (subscriptionType) {
          return <NamedRedirect name="NewListingPage" />;
        } /* else not needed */
      }
    }
  } /* else not needed */

  const plans = Object.entries(paymentLinks).map(
    ([_, { name, description, monthlyPrice, yearlyPrice, monthly, yearly }]) => ({
      name: name,
      description: description,
      monthlyPrice: monthlyPrice,
      yearlyPrice: yearlyPrice,
      yearlyDiscount: '10',
      options: [
        // TODO-GB: Move labels to translation file
        {
          key: 'monthly',
          value: process.env.REACT_APP_ENV === 'development' ? monthly.dev : monthly.prod,
          price: monthlyPrice,
        },
        {
          key: 'yearly',
          value: process.env.REACT_APP_ENV === 'development' ? yearly.dev : yearly.prod,
          price: yearlyPrice,
        },
      ],
    })
  );

  const handleOptionSelect = option => {
    window.location.href = `${option.value}?client_reference_id=${user.id.uuid}&prefilled_email=${user.attributes.email}`;
  };

  return (
    // TODO-GB: Move labels to translation file
    <Page title="Subscription Selection" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer currentPage="SubscriptionSelectionPage" />
            <UserNav currentPage="SubscriptionSelectionPage" />
          </>
        }
        footer={<FooterContainer />}
      >
        <div className={css.container}>
          {/* TODO-GB: Move labels to translation file */}
          <h2>Select your subscription plan</h2>
          <div className={css.billingToggle}>
            <button
              className={`${css.billingButton} ${
                selectedBillingPeriod === 'monthly' ? css.active : ''
              }`}
              onClick={() => setSelectedBillingPeriod('monthly')}
            >
              {/* TODO-GB: Move labels to translation file */}
              Pay Monthly
            </button>
            <button
              className={`${css.billingButton} ${
                selectedBillingPeriod === 'yearly' ? css.active : ''
              }`}
              onClick={() => setSelectedBillingPeriod('yearly')}
            >
              {/* TODO-GB: Move labels to translation file */}
              Pay Yearly
              <span className={css.savingsBadge}>Save 10%</span>
            </button>
          </div>
          <div className={css.plansGrid}>
            {plans.map(plan => (
              <div key={plan.name} className={css.planCard}>
                <h3>{plan.name}</h3>
                <p className={css.planDescription}>
                  <span dangerouslySetInnerHTML={{ __html: plan.description }} />
                </p>
                <div className={css.pricingInfo}>
                  <div className={css.price}>
                    <span className={css.amount}>
                      {selectedBillingPeriod === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}
                    </span>
                    <span className={css.period}>
                      /{selectedBillingPeriod === 'monthly' ? 'month' : 'year'}
                    </span>
                  </div>
                  {/* TODO-GB: Move labels to translation file */}
                  {selectedBillingPeriod === 'yearly' && (
                    <div className={css.savings}>Save {plan.yearlyDiscount}% annually</div>
                  )}
                </div>
                <button
                  className={css.subscribeButton}
                  onClick={() =>
                    handleOptionSelect(
                      plan.options.find(option => option.key === selectedBillingPeriod)
                    )
                  }
                >
                  {/* TODO-GB: Move labels to translation file */}
                  Select
                </button>
              </div>
            ))}
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

SubscriptionSelectionPageComponent.defaultProps = {
  currentUser: null,
  scrollingDisabled: false,
};

SubscriptionSelectionPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  scrollingDisabled: isScrollingDisabled(state),
});

const SubscriptionSelectionPage = compose(
  withRouter,
  connect(mapStateToProps)
)(SubscriptionSelectionPageComponent);

export default SubscriptionSelectionPage;
