import React from 'react';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_TAX } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTaxRefundMaybe = props => {
  const { lineItems, intl } = props;

  const refund = lineItems.find(item => item.code === LINE_ITEM_TAX && item.reversal);

  return refund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.refundTax" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, refund.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemTaxRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxRefundMaybe;
