import { stripeApi } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const CANCEL_SUBSCRIPTION_REQUEST =
  'app/SubscriptionManagementPage/CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS =
  'app/SubscriptionManagementPage/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'app/SubscriptionManagementPage/CANCEL_SUBSCRIPTION_ERROR';

export const REACTIVATE_SUBSCRIPTION_REQUEST =
  'app/SubscriptionManagementPage/REACTIVATE_SUBSCRIPTION_REQUEST';
export const REACTIVATE_SUBSCRIPTION_SUCCESS =
  'app/SubscriptionManagementPage/REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_ERROR =
  'app/SubscriptionManagementPage/REACTIVATE_SUBSCRIPTION_ERROR';

export const GET_SUBSCRIPTION_DETAILS_REQUEST =
  'app/SubscriptionManagementPage/GET_SUBSCRIPTION_DETAILS_REQUEST';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS =
  'app/SubscriptionManagementPage/GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_ERROR =
  'app/SubscriptionManagementPage/GET_SUBSCRIPTION_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  cancelSubscriptionInProgress: false,
  cancelSubscriptionError: null,
  reactivateSubscriptionInProgress: false,
  reactivateSubscriptionError: null,
  getSubscriptionDetailsInProgress: false,
  getSubscriptionDetailsError: null,
  subscriptionDetails: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { ...state, cancelSubscriptionInProgress: true, cancelSubscriptionError: null };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { ...state, cancelSubscriptionInProgress: false, subscriptionDetails: payload };
    case CANCEL_SUBSCRIPTION_ERROR:
      return { ...state, cancelSubscriptionInProgress: false, cancelSubscriptionError: payload };
    case REACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        reactivateSubscriptionInProgress: true,
        reactivateSubscriptionError: null,
      };
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return { ...state, reactivateSubscriptionInProgress: false, subscriptionDetails: payload };
    case REACTIVATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        reactivateSubscriptionInProgress: false,
        reactivateSubscriptionError: payload,
      };
    case GET_SUBSCRIPTION_DETAILS_REQUEST:
      return {
        ...state,
        getSubscriptionDetailsInProgress: true,
        getSubscriptionDetailsError: null,
      };
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        getSubscriptionDetailsInProgress: false,
        subscriptionDetails: payload,
      };
    case GET_SUBSCRIPTION_DETAILS_ERROR:
      return {
        ...state,
        getSubscriptionDetailsInProgress: false,
        getSubscriptionDetailsError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const cancelSubscriptionRequest = () => ({ type: CANCEL_SUBSCRIPTION_REQUEST });
export const cancelSubscriptionSuccess = payload => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});
export const cancelSubscriptionError = error => ({
  type: CANCEL_SUBSCRIPTION_ERROR,
  payload: error,
  error: true,
});

export const reactivateSubscriptionRequest = () => ({ type: REACTIVATE_SUBSCRIPTION_REQUEST });
export const reactivateSubscriptionSuccess = payload => ({
  type: REACTIVATE_SUBSCRIPTION_SUCCESS,
  payload,
});
export const reactivateSubscriptionError = error => ({
  type: REACTIVATE_SUBSCRIPTION_ERROR,
  payload: error,
  error: true,
});

export const getSubscriptionDetailsRequest = () => ({ type: GET_SUBSCRIPTION_DETAILS_REQUEST });
export const getSubscriptionDetailsSuccess = subscriptionDetails => ({
  type: GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: subscriptionDetails,
});
export const getSubscriptionDetailsError = error => ({
  type: GET_SUBSCRIPTION_DETAILS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const cancelSubscription = () => (dispatch, getState, sdk) => {
  dispatch(cancelSubscriptionRequest());
  return stripeApi
    .cancelSubscription({})
    .then(response => {
      dispatch(cancelSubscriptionSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(cancelSubscriptionError(storableError(e))));
};

export const reactivateSubscription = () => (dispatch, getState, sdk) => {
  dispatch(reactivateSubscriptionRequest());
  return stripeApi
    .reactivateSubscription({})
    .then(response => {
      dispatch(reactivateSubscriptionSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(reactivateSubscriptionError(storableError(e))));
};

export const getSubscriptionDetails = () => (dispatch, getState, sdk) => {
  dispatch(getSubscriptionDetailsRequest());
  return stripeApi
    .getSubscriptionDetails({})
    .then(response => dispatch(getSubscriptionDetailsSuccess(response.data)))
    .catch(e => dispatch(getSubscriptionDetailsError(storableError(e))));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return dispatch(getSubscriptionDetails());
};
