import React, { useState } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import css from './SubscriptionPlansPage.module.css';
import { paymentLinks } from '../../config/configStripe';

export const SubscriptionPlansComponent = props => {
  const { scrollingDisabled } = props;
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState('monthly');

  const plans = Object.entries(paymentLinks).map(
    ([_, { name, description, monthlyPrice, yearlyPrice, monthly, yearly }]) => ({
      name: name,
      description: description,
      monthlyPrice: monthlyPrice,
      yearlyPrice: yearlyPrice,
      yearlyDiscount: '10',
      options: [
        // TODO-GB: Move labels to translation file
        {
          key: 'monthly',
          value: process.env.REACT_APP_ENV === 'development' ? monthly.dev : monthly.prod,
          price: monthlyPrice,
        },
        {
          key: 'yearly',
          value: process.env.REACT_APP_ENV === 'development' ? yearly.dev : yearly.prod,
          price: yearlyPrice,
        },
      ],
    })
  );

  const handleOptionSelect = () => {
    window.location.href = '/login';
  };

  return (
    // TODO-GB: Move labels to translation file
    <Page title="Subscription Plans" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer currentPage="SubscriptionPlansPage" />
          </>
        }
        footer={<FooterContainer />}
      >
        <div className={css.container}>
          {/* TODO-GB: Move labels to translation file */}
          <h2>Subscription plans</h2>
          <div className={css.billingToggle}>
            <button
              className={`${css.billingButton} ${
                selectedBillingPeriod === 'monthly' ? css.active : ''
              }`}
              onClick={() => setSelectedBillingPeriod('monthly')}
            >
              {/* TODO-GB: Move labels to translation file */}
              Pay Monthly
            </button>
            <button
              className={`${css.billingButton} ${
                selectedBillingPeriod === 'yearly' ? css.active : ''
              }`}
              onClick={() => setSelectedBillingPeriod('yearly')}
            >
              {/* TODO-GB: Move labels to translation file */}
              Pay Yearly
              <span className={css.savingsBadge}>Save 10%</span>
            </button>
          </div>
          <div className={css.plansGrid}>
            {plans.map(plan => (
              <div key={plan.name} className={css.planCard}>
                <h3>{plan.name}</h3>
                <p className={css.planDescription}>
                  <span dangerouslySetInnerHTML={{ __html: plan.description }} />
                </p>
                <div className={css.pricingInfo}>
                  <div className={css.price}>
                    <span className={css.amount}>
                      {selectedBillingPeriod === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}
                    </span>
                    <span className={css.period}>
                      /{selectedBillingPeriod === 'monthly' ? 'month' : 'year'}
                    </span>
                  </div>
                  {/* TODO-GB: Move labels to translation file */}
                  {selectedBillingPeriod === 'yearly' && (
                    <div className={css.savings}>Save {plan.yearlyDiscount}% annually</div>
                  )}
                </div>
                <button className={css.subscribeButton} onClick={handleOptionSelect}>
                  {/* TODO-GB: Move labels to translation file */}
                  Login to Select
                </button>
              </div>
            ))}
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

SubscriptionPlansComponent.defaultProps = {
  scrollingDisabled: false,
};

SubscriptionPlansComponent.propTypes = {
  scrollingDisabled: bool,
};

const mapStateToProps = state => ({
  scrollingDisabled: isScrollingDisabled(state),
});

const SubscriptionPlansPage = compose(
  withRouter,
  connect(mapStateToProps)
)(SubscriptionPlansComponent);

export default SubscriptionPlansPage;
